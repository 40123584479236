var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Rr2N3gT6O-lyYJGd4RCdw"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/4.4.0-1742881224023";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  REPLAYS_ONERROR_SAMPLE_RATE,
  REPLAYS_SESSION_SAMPLE_RATE,
} from '@src/constants/sentryConfig';
import beforeSend from '@src/utils/sentry/beforeSendSentry';
import { checkForEnabled } from '@src/utils/sentry/common';

const { version } = require('./package.json');

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const sentryConfig: Sentry.BrowserOptions = {
  dsn: SENTRY_DSN,
  enabled: checkForEnabled(),

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  replaysOnErrorSampleRate: REPLAYS_ONERROR_SAMPLE_RATE,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: REPLAYS_SESSION_SAMPLE_RATE,

  environment: process.env.NEXT_PUBLIC_APP_ENV,
  release: version,
  beforeSend,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
};

Sentry.init(sentryConfig);
